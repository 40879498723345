import React, {ReactElement} from "react"
import {
  DialogContent as MUIDialogContent
} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {IOrder} from "../../Order/interfaces/order.interface";
import {Delivery} from "./components/Buttons/Delivery";
import {getType} from "../../Store/helpers/type";
import Typography from "@mui/material/Typography";
import {Params as RenderParams} from "../../Order/components/Order/Params";
import {getPrice} from "../../Store/helpers/price";
import {IStatus} from "../../Order/interfaces/status.interaface";
import {Params} from "./Params";
import {Status} from "./components/Buttons/Status";
import {Dialog} from "../../App/components/Dialog";
import {status} from "../../Order/constants/status";

const DialogContent = styled(MUIDialogContent)(({theme}) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

const Identity = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  color: "rgb(148 163 184)"
}))

const Created = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  color: "rgb(148 163 184)"
}))

const Tag = styled(Typography)(({theme}) => ({
  fontWeight: "500",
  fontSize: "0.8rem",
  backgroundColor: "rgb(15, 23, 42)",
  color: "rgb(255 255 255)",
  padding: "4px 10px 4px 10px",
  borderRadius: "9999px",
}))

interface Props {
  item: IOrder,
  setItem: (order: IOrder) => void
  open: boolean,
  onClose: () => void
}

export function Order(props: Props): ReactElement {
  const { item, setItem, open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <DialogContent>
        <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
          <Grid item>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <Grid item>
                    <Identity>
                      ID: {item.id}
                    </Identity>
                  </Grid>
                  <Grid item>
                    <Tag>
                      {getType(item.position.type.key)}
                    </Tag>
                  </Grid>
                  <Grid item>
                    <Created>
                      {item.created ? new Date(item.created).toLocaleString() : null}
                    </Created>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Status
                  order={item}
                  setOrder={(order: IOrder) => {
                    setItem(order)
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography>
              {item.position.parent ? item.position.parent.name : item.position.name}
            </Typography>
            {item.position.parent ? (
              <Typography variant="body2">
                {item.position.name}
              </Typography>
            ) : null}
          </Grid>
          {item.account ? (
            <Grid item>
              <Typography variant="body2">
                {item.account.email}
              </Typography>
            </Grid>
          ) : null}
          <Params
            item={item}
            onClick={(order: IOrder) => {
              setItem(order)
            }}
          />
          {(item.transaction && [status.PAID.id, status.PERFORMED.id, status.COMPLETED.id].includes(item.status.id)) ? (
            <React.Fragment>
              <Grid item>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                  <Grid item>
                    <Typography>Оплата</Typography>
                  </Grid>
                  <Grid item>
                    <Created>{item.transaction.created ? new Date(item.transaction.created).toLocaleString() : null}</Created>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {RenderParams({
                  'Номер транзакции': item.transaction.number,
                  'Сумма': getPrice('price', item.transaction.price)
                })}
              </Grid>
            </React.Fragment>
          ) : null}
          <Grid item>
            <Grid container direction="row" justifyContent="flex-end" alignItems="stretch" spacing={2}>
              <Grid item>
                <Delivery
                  order={item}
                  setStatus={(status: IStatus) => {
                    setItem({
                      ...item,
                      status: status
                    })
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}