import {ReactElement} from "react"
import {useParams} from "react-router-dom";
import {Replenishment as SteamReplenishment} from "./Services/Steam/Replenishment";
import {Payment as SteamPayment} from "./Services/Steam/Payment";
import {Genshin} from "./Services/Genshin";

export function Position(): ReactElement | null {
  const params = useParams()

  switch (params.type) {
    case 'service':
      switch (params[params.type]) {
        case 'steam':
          switch (params.method) {
            case 'replenishment':
              return <SteamReplenishment />;
            case 'payment':
              return <SteamPayment />;
          }
          break;
        case 'genshin':
          return <Genshin />
      }
      break;
  }

  return null;
}
