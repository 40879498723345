import React, {ReactElement} from 'react';
import Grid from "@mui/material/Grid";
import {
  CardContent as MUICardContent,
  DialogContent as MUIDialogContent,
  IconButton,
  Stack,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {Close as CloseIcon, PaymentOutlined, QrCodeOutlined, WalletOutlined} from "@mui/icons-material";
import {Dialog} from "../../../App/components/Dialog";
import {alpha, styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {Card as BaseContent} from "../../../App/components/Card";

const Content = styled(BaseContent)(({theme}) => ({
  cursor: "pointer",
  '& .MuiCardContent-root': {
    padding: "8px",
  },
  color: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.primary.dark,
    backgroundColor: alpha(theme.palette.primary.main, 0.05)
  },
}))

const CardContent = styled(MUICardContent)(({theme}) => ({
  paddingBottom: "8px !important"
}))

const DialogContent = styled(MUIDialogContent)(({theme}) => ({
  minWidth: "375px",
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

const Title = styled(Typography)(() => ({
  fontWeight: "500",
  fontSize: "1.3rem"
}))

const Type = styled(Typography)(({theme}) => ({
  fontWeight: '500',
  fontSize: "0.9rem",
  lineHeight: 0,
  color: "rgba(0, 0, 0, 0.6)",
  whiteSpace: "pre-wrap"
}))

interface Props {
  handler: (type: 'payment' | 'wallet', method?: string) => void,
  open: boolean,
  onClose: () => void,
  types?: Array<string>
}

export function Methods(props: Props): ReactElement | null {
  const { handler, open, onClose, types = ['card', 'sbp', 'wallet'] } = props
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const methods = {
    'card': (
      <Content
        onClick={() => {
          handler('payment', 'card')
          onClose()
        }}
      >
        <CardContent>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={4}>
              <PaymentOutlined sx={{fontSize: 40, display: "block"}}/>
            </Grid>
            <Grid item xs={8}>
              <Type>
                Карта
              </Type>
            </Grid>
          </Grid>
        </CardContent>
      </Content>
    ),
    'sbp': (
      <Content
        onClick={() => {
          handler('payment', 'sbp')
          onClose()
        }}
      >
        <CardContent>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={4}>
              <QrCodeOutlined sx={{ fontSize: 40, display: "block" }} />
            </Grid>
            <Grid item xs={8}>
              <Type>
                СБП
              </Type>
            </Grid>
          </Grid>
        </CardContent>
      </Content>
    ),
    'wallet': (
      <Content
        onClick={() => {
          handler('wallet')
          onClose()
        }}
      >
        <CardContent>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid item xs={4}>
              <WalletOutlined sx={{ fontSize: 40, display: "block" }} />
            </Grid>
            <Grid item xs={8}>
              <Type>
                Баланс
              </Type>
            </Grid>
          </Grid>
        </CardContent>
      </Content>
    )
  }

  return (open ? <Dialog
    fullScreen={mobile}
    open={open}
    onClose={onClose}
    maxWidth="sm"
  >
    <DialogContent>
      {mobile ? (
        <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
          <IconButton edge="start" color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      ) : null}
      <Stack sx={{height: mobile ? "calc(100% - 40px)" : "100%"}} direction="column" alignItems="stretch" justifyContent="center">
        <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
          <Grid item>
            <Title>Способ оплаты</Title>
          </Grid>
          <Grid item>
            <Grid container direction={mobile ? "column" : "row"} justifyContent="center" alignItems="stretch" spacing={2}>
              {Object.entries(methods).filter(([key]) => types.includes(key)).map(([_, method]) => (
                <Grid item xs={mobile ? 12 : (types.length > 2) ? 4 : 6}>
                  {method}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </DialogContent>
  </Dialog> : null)
}