import React, {ReactElement, useEffect, useState} from "react"
import {useAppDispatch} from "../../App/hooks/store";
import {IPosition} from "../../Store/interfaces/position.interface";
import {
  Autocomplete, Chip,
  DialogContent as MUIDialogContent,
  IconButton,
  InputLabel, MenuItem, Stack, Switch, useTheme
} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {Controller, SubmitHandler, useFieldArray, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {IPositionState as IPositionStateBase} from "./interfaces/position";
import {type} from "../../Store/constants/type";
import {service} from "../../Store/constants/service";
import {PositionActions} from "./actions/position";
import Box from "@mui/material/Box";
import {AddCircleOutline} from "@mui/icons-material";
import {List} from "./components/Buttons/List";
import {TextField} from "../../App/components/Input/TextField";
import {Input} from "../../App/components/Input/Input";
import {FormControl} from "../../App/components/Input/FormControl";
import {FormHelperText} from "../../App/components/Input/FormHelperText";
import {Button} from "../../App/components/Button";
import {Field} from "../../App/components/Form/Field";
import {useDebouncedCallback} from "use-debounce";
import {IFilter} from "../../App/interfaces/filter";
import {Dialog} from "../../App/components/Dialog";
import {Select} from "../../App/components/Input/Select";
import {IGroup} from "../../Account/interfaces/group";
import {GroupActions} from "../Account/actions/group";

const DialogContent = styled(MUIDialogContent)(({theme}) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

const Label = styled(InputLabel)(() => ({
  color: "rgb(22 28 45)",
  fontSize: 20,
  '&.Mui-focused': {
    color: "rgb(22 28 45)",
  },
}));

const HiddenInput = styled("input")({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

interface Props {
  item?: IPosition,
  open: boolean,
  onClose: (position?: IPosition) => void
}

interface IPositionState extends Omit<IPositionStateBase, 'groups'> {
  groups?: Array<IGroup>
}

export function Position(props: Props): ReactElement {
  const {item, open, onClose} = props;
  const dispatch = useAppDispatch();
  const theme = useTheme()

  const [items, setItems] = useState<Array<IPosition>>([])
  const [groups, setGroups] = useState<Array<IGroup>>([])
  const [search, setSearch] = useState<{type: null | 'position' | 'group', search: null | string}>({type: null, search: null})

  const debounced = useDebouncedCallback(
    (value) => {
      setSearch(value);
    },
    900
  );

  useEffect(() => {
    if ((search.type === 'position') && search.search) {
      dispatch(PositionActions.items({
        page: 1,
        size: 10,
        type: type.PRODUCT.id,
        ...(item ? {exclude: item.id} : {}),
        ...(search.search ? {search: search.search} : {}),
      } as IFilter)).then(positions => {
        setItems(positions.data)
      })
    }
  }, [dispatch, search]);

  useEffect(() => {
    if ((search.type === 'group') && search.search) {
      dispatch(GroupActions.items({
        page: 1,
        size: 10,
        ...(search.search ? {search: search.search} : {}),
      } as IFilter)).then(groups => {
        setGroups(groups.data)
      })
    }
  }, [dispatch, search]);

  const {formState: {isSubmitSuccessful, errors}, control, handleSubmit, getValues} = useForm({
    defaultValues: {
      name: item?.name ?? "",
      parent: item?.parent,
      active: item ? item.active : false,
      description: item?.description ?? "",
      type: item?.type.id ?? type.PRODUCT.id,
      service: item?.service?.id,
      provider: item?.service?.provider,
      price: item?.price ?? 0,
      commission: item?.commission ?? 0,
      image: undefined,
      options: Object.entries(item?.options ?? {}).reduce((result: {[key: string]: string | null}, [key, value]) => {
        result[key] = value.value ?? ''

        return result
      }, {}),
      groups: item?.groups ?? []
    },
    resolver: yupResolver(Yup
      .object({
        name: Yup.string().required("Введите наименовние"),
        parent: Yup.object().nullable(),
        active: Yup.boolean().default(false),
        description: Yup.string(),
        type: Yup.number().required("Выберите тип"),
        service: Yup.number().when('type', ([value], schema) => (value === type.SERVICE.id) ? schema.required("Выберите услугу") : schema),
        provider: Yup.string().when('type', ([value], schema) => (value === type.SERVICE.id) ? schema.required("Выберите поставщика") : schema),
        price: Yup.number().typeError('Укажите стоимость').min(0.01, `Минимальная сумма 0.01 ₽`).required("Укажите стоимость"),
        commission: Yup.number().typeError('Укажите коммиссию').min(0, `Минимальная сумма 0 ₽`),
        image: Yup.mixed(),
        options: Yup.object(
          Object.entries(item?.options ?? {}).reduce((result: {[key: string]: any}, [key, value]) => {
            switch (value.type) {
              case 'number':
                result[key] = value.required ? Yup.number().required('Необходимо заполнить данные') : Yup.mixed().nullable()
                break;
              default:
                result[key] = value.required ? Yup.string().required('Необходимо заполнить данные') : Yup.string()
            }

            return result
          }, {})
        ),
        groups: Yup.array()
      }).required()),
  })

  const { fields, replace, remove } = useFieldArray({
    control,
    name: "groups"
  });

  const onSubmit: SubmitHandler<IPositionState> = (values) => {
    const data = new FormData();

    Object.entries(values).forEach(([key, param]) => {
      switch (key) {
        case 'parent':
          if (param) {
            data.append(key, param.id.toString())
          }
          break;
        case 'options':
          if (item?.options) {
            data.append(key, JSON.stringify(Object.entries(item?.options ?? {}).reduce((result: {[key: string]: object}, [key, value]) => {
              result[key] = {
                ...value,
                value: !param[key] || !param[key].length ? null : param[key]
              }

              return result
            }, {})))
          }
          break;
        case 'groups':
          data.append(key, JSON.stringify(values.groups?.map(group => group.id)))
          break;
        default:
          data.append(key, param ?? null)
      }
    });

    dispatch(item ? PositionActions.update(item.id, data) : PositionActions.create(data)).then(
      async (position) => {
        onClose(position)
      }
    )
  }

  const isService = (type.SERVICE.id === getValues('type' as keyof object))

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="md"
    >
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
                <Grid item>
                  <Controller
                    name="active"
                    control={control}
                    render={({
                      field: {onChange, value}
                    }) => (
                      <Switch
                        checked={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={2}>
                <Grid item xs={8}>
                  <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
                    <Field>
                      <Controller
                        name="name"
                        control={control}
                        render={({
                          field: {onChange, value}, fieldState
                        }) => (
                          <TextField
                            required
                            label="Наименование:"
                            error={!!fieldState.error}
                            onChange={onChange}
                            value={value}
                            helperText={fieldState.error?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Field>
                    {(!isService && !item?.positions?.length) || (isService && !!item?.parent) ? (
                      <Grid item>
                        <Controller
                          name="parent"
                          control={control}
                          render={({
                            field: { onChange, value }, fieldState
                          }) => (
                            <Autocomplete
                              disabled={isService}
                              getOptionLabel={(option: IPosition) => option.name}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              noOptionsText={"Нет данных"}
                              options={items}
                              loading={false}
                              value={value as IPosition}
                              onChange={(e, value) => {
                                onChange(value)
                              }}
                              renderTags={() => null}
                              renderInput={(params) => (
                                <TextField
                                  label="Товар/Услуга:"
                                  error={!!fieldState.error}
                                  {...params}
                                  onChange={(e) => {
                                    debounced({search: e.target.value, type: 'position'})
                                  }}
                                  helperText={fieldState.error?.message as string}
                                />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    ) : null}
                    <Grid item>
                      <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={2}>
                        <Field xs={6}>
                          <Controller
                            name="type"
                            control={control}
                            render={({
                              field: {onChange, value}, fieldState
                            }) => (
                              <FormControl required fullWidth variant="standard">
                                <Label shrink>
                                  Тип:
                                </Label>
                                <Select
                                  value={value}
                                  disabled
                                  error={!!fieldState.error}
                                  onChange={onChange}
                                  input={<Input/>}
                                  fullWidth
                                >
                                  {Object.entries(type).map(([key, type]) => (
                                    <MenuItem key={key} value={type.id}>{type.name}</MenuItem>
                                  ))}
                                </Select>
                                <FormHelperText>{fieldState.error?.message}</FormHelperText>
                              </FormControl>
                            )}
                          />
                        </Field>
                        {isService ? (
                          <Field xs={6}>
                            <Controller
                              name="service"
                              control={control}
                              render={({
                                field: {onChange, value}, fieldState
                              }) => (
                                <FormControl required fullWidth variant="standard">
                                  <Label shrink>
                                    Услуга:
                                  </Label>
                                  <Select
                                    value={value}
                                    disabled
                                    error={!!fieldState.error}
                                    onChange={onChange}
                                    input={<Input/>}
                                    fullWidth
                                  >
                                    {service.map((service) => (
                                      <MenuItem key={service.id} value={service.id}>{`${service.name} (${service.method.name})`}</MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                </FormControl>
                              )}
                            />
                          </Field>
                        ) : null}
                        {(item?.service && !item.parent) ? (
                          <Field xs={12}>
                            <Controller
                              name="provider"
                              control={control}
                              render={({
                                field: {onChange, value}, fieldState
                              }) => (
                                <FormControl required fullWidth variant="standard">
                                  <Label shrink>
                                    Поставщик:
                                  </Label>
                                  <Select
                                    value={value}
                                    error={!!fieldState.error}
                                    onChange={onChange}
                                    input={<Input/>}
                                    fullWidth
                                  >
                                    {item?.service?.params.map((provider, index) => (
                                      <MenuItem key={index} value={provider.key}>{provider.name}</MenuItem>
                                    ))}
                                  </Select>
                                  <FormHelperText>{fieldState.error?.message}</FormHelperText>
                                </FormControl>
                              )}
                            />
                          </Field>
                        ) : null}
                        <Field xs={6}>
                          <Controller
                            name="price"
                            control={control}
                            render={({
                              field: {onChange, value}, fieldState
                            }) => (
                              <TextField
                                required
                                label="Стоимость:"
                                type="number"
                                error={!!fieldState.error}
                                onChange={onChange}
                                value={value}
                                helperText={fieldState.error?.message}
                                fullWidth
                              />
                            )}
                          />
                        </Field>
                        {(item?.service && (!item.parent && !item.positions?.length)) ? (
                          <Field xs={6}>
                            <Controller
                              name="commission"
                              control={control}
                              render={({
                                field: {onChange, value}, fieldState
                              }) => (
                                <TextField
                                  label="Комиссия:"
                                  type="number"
                                  error={!!fieldState.error}
                                  onChange={onChange}
                                  inputProps={{min: 0}}
                                  value={value}
                                  helperText={fieldState.error?.message}
                                  fullWidth
                                />
                              )}
                            />
                          </Field>
                        ) : null}
                      </Grid>
                    </Grid>
                    {item?.options ? (
                      <Grid item>
                        {
                          Object.entries(item.options).map(([key, option], index) => (
                            <Field xs={6} key={index}>
                              <Controller
                                name={`options.${key}` as keyof object}
                                control={control}
                                render={({
                                  field: { onChange, value }, fieldState
                                }) => (
                                  <TextField
                                    required={option.required}
                                    label={`${option.name}:`}
                                    type={option.type}
                                    error={!!fieldState.error}
                                    onChange={onChange}
                                    value={value}
                                    helperText={fieldState.error?.message}
                                    fullWidth
                                  />
                                )}
                              />
                            </Field>
                          ))
                        }
                      </Grid>
                    ) : null}
                    <Grid item>
                      <Controller
                        name="description"
                        control={control}
                        render={({
                          field: {onChange, value}, fieldState
                        }) => (
                          <TextField
                            label="Описание:"
                            error={!!fieldState.error}
                            multiline
                            rows={4}
                            onChange={onChange}
                            value={value}
                            helperText={fieldState.error?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
                    <Grid item>
                      <Controller
                        name="image"
                        control={control}
                        render={({
                          field: {onChange, value}
                        }) => (
                          <React.Fragment>
                            <Box
                              sx={{
                                aspectRatio: "2/3",
                                width: "100%",
                                height: "317px",
                                ...((value || item?.image) ? {backgroundImage: `url(${value ? URL.createObjectURL(value as MediaSource) : (item?.image ? `${process.env.REACT_APP_API_HOST}/file/${item.image.id}` : null)});`} : {}),
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                borderRadius: "1rem",
                                border: "1px solid",
                                borderColor: "rgb(229 231 235)"
                              }}
                            >
                              <Stack
                                sx={{height: "100%"}}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                              >
                                <Box>
                                  <IconButton
                                    component="label"
                                    color="primary"
                                    edge="start"
                                  >
                                    <AddCircleOutline fontSize="large"/>
                                    <HiddenInput
                                      type="file"
                                      accept="image/*"
                                      onChange={(event) => {
                                        const files = event.target.files;
                                        if (files?.length) {
                                          onChange(files.item(0))
                                        }
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              </Stack>
                            </Box>
                          </React.Fragment>
                        )}
                      />
                    </Grid>
                    <Grid item>
                      <Controller
                        name="groups"
                        control={control}
                        render={({
                          field: { value }, fieldState
                        }) => (
                          <React.Fragment>
                            <Autocomplete
                              multiple
                              getOptionLabel={(option: IGroup) => option.name}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              noOptionsText={"Нет данных"}
                              options={groups}
                              loading={false}
                              value={value}
                              onChange={(e, value) => {
                                replace(value)
                              }}
                              renderTags={() => null}
                              renderInput={(params) => (
                                <TextField
                                  label="Группа:"
                                  error={!!fieldState.error}
                                  {...params}
                                  onChange={(e) => {
                                    debounced({search: e.target.value, type: 'group'})
                                  }}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                            {fields.length ? (
                              <Box
                                sx={{
                                  padding: `${theme.spacing(1.5)} 0`,
                                  display: "flex",
                                  flexFlow: "row wrap",
                                  gridGap: theme.spacing(1.5)
                                }}
                              >
                                {fields.map((group: IGroup, index) => (
                                  <Chip key={index} variant="outlined" label={group.name} onDelete={() => remove(index)} />
                                ))}
                              </Box>
                            ) : null}
                          </React.Fragment>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent={(!item || isService) ? "flex-end" : "space-between"} alignItems="stretch">
                {item ? <List position={item} /> : null}
                <Grid item>
                  <Button
                    disabled={isSubmitSuccessful}
                    size="large"
                    type="submit"
                  >
                    {item ? "Сохранить" : "Добавить"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}