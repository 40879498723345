import {method} from "./method";

const service = [
  {
    id: 1,
    key: 'steam',
    name: 'Steam',
    method: method.REPLENISHMENT
  },
  {
    id: 2,
    key: 'genshin',
    name: 'Genshin Impact',
    method: method.REPLENISHMENT
  },
  {
    id: 3,
    key: 'wallet',
    name: 'Баланс',
    method: method.REPLENISHMENT
  },
  {
    id: 4,
    key: 'steam',
    name: 'Steam',
    method: method.PAYMENT
  },
]

export { service }