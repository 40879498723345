
const service = {
  steam: [
    {
      key: 'interhub',
      name: 'OSON (InterHub)'
    },
    {
      key: 'hgg',
      name: 'Hermes Garant Group'
    },
    {
      key: 'mp',
      name: 'MP.ge'
    },
    {
      key: 'emoney',
      name: 'EMoney'
    },
    {
      key: 'profitable',
      name: 'ProfiTable'
    },
    {
      key: 'unlockify',
      name: 'Unlockify'
    },
  ]
}

export { service }