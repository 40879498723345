import React, {ReactElement, useState} from "react"
import {useAppDispatch} from "../../../../App/hooks/store";
import {OrderActions} from "../../../../Store/actions/order";
import {Button} from "../../../../App/components/Button";
import {Methods} from "../../../../Store/components/Payment/Methods";
import {useModal} from "../../../../App/hooks/component";
import {IOrder} from "../../../interfaces/order.interface";
import {AlertActionsTypes} from "../../../../App/interfaces/alert";

interface Props {
  order: IOrder
}

export function Pay(props: Props): ReactElement {
  const dispatch = useAppDispatch();
  const { order } = props
  const [isSubmit, setIsSubmit] = useState(false)
  const [isShowingModal, toggleModal] = useModal()

  const onClick = (payment?: 'payment' | 'wallet', method?: string) => {
    if (!payment) {
        return toggleModal()
    }

    setIsSubmit(true)
    dispatch(OrderActions.bill(order.id, payment ?? 'wallet', method)).then(bill => {
      window.location.replace(bill.link);
    }).catch(error => {
      dispatch({
        type: AlertActionsTypes.ERROR,
        payload: {
          type: "error",
          message: error,
        }
      })
      setIsSubmit(false)
    })
  }

  return (
    <React.Fragment>
      <Button
        disabled={isSubmit}
        size="large"
        type="button"
        onClick={() => onClick()}
      >
        Оплатить
      </Button>
      <Methods
        handler={(type, method?) => {
          onClick(type, method)
        }}
        open={isShowingModal}
        onClose={toggleModal}
        types={[
          'card', 'sbp',
          ...(order.position.service?.method?.key !== 'payment') ? ['wallet'] : []
        ]}
      />
    </React.Fragment>
  )
}
