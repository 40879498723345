import React, {ReactElement, useEffect, useState} from "react"
import {useAppDispatch} from "../../../App/hooks/store";
import {
  Autocomplete,
  DialogContent as MUIDialogContent
} from "@mui/material";
import {styled} from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {OrderActions} from "../actions/order";
import {IOrder} from "../../../Order/interfaces/order.interface";
import {IOrderState} from "../interfaces/order";
import {IPosition} from "../../../Store/interfaces/position.interface";
import {TextField} from "../../../App/components/Input/TextField";
import {useDebouncedCallback} from "use-debounce";
import {PositionActions} from "../../Store/actions/position";
import {IFilter} from "../../../App/interfaces/filter";
import {type} from "../../../Store/constants/type";
import {AnyObjectSchema, ObjectSchema} from "yup";
import {AlertActionsTypes} from "../../../App/interfaces/alert";
import {Button} from "../../../App/components/Button";
import {Field} from "../../../App/components/Form/Field";
import {Dialog} from "../../../App/components/Dialog";
import {Account} from "../../../Account/components/Controllers/Account";
import {service} from "../../Store/constants/service";

const DialogContent = styled(MUIDialogContent)(({theme}) => ({
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
}))

interface Props {
  open: boolean,
  onClose: (order?: IOrder) => void
}

export function Order(props: Props): ReactElement {
  const {open, onClose} = props;
  const dispatch = useAppDispatch();

  const [position, setPosition] = useState<IPosition | null>()
  const [items, setItems] = useState<Array<IPosition>>([])
  const [search, setSearch] = useState(null)

  const debounced = useDebouncedCallback(
    (value) => {
      setSearch(value);
    },
    900
  );

  useEffect(() => {
    if (search) {
      dispatch(PositionActions.items({
        page: 1,
        size: 10,
        management: true,
        ...(search ? {search: search} : {}),
      } as IFilter)).then(positions => {
        setItems(positions.data)
      })
    }
  }, [dispatch, search]);

  const schema = Yup
    .object({
      manual: Yup.boolean().required('Ручное создание заказа'),
      position: Yup.object().required("Выберите товар/услугу"),
      price: Yup.number().typeError('Введите сумму').min(position?.price ?? 0, `Минимальная сумма ${position?.price ?? 0} ₽`).required("Введите сумму"),
      email: Yup.string().email('Некорректный формат электронной почты').required("Введите адрес электронной почты"),
      code: Yup.string(),
      params: Yup.object({
        ...(position?.params ? Object.entries(position?.params).reduce((obj, [name, param]) => {
          let schema;

          switch (param.type) {
            case 'string':
            default:
              schema = Yup.string()
          }

          if (param.required) {
            schema = schema.required('Необходимо заполнить данные')
          }

          return {
            ...obj,
            [name]: schema
          }
        }, {}) as {[key: string]: ObjectSchema<AnyObjectSchema>} : {})
      })
    }).required()

  const {formState: {isSubmitSuccessful}, control, handleSubmit, setError, clearErrors, setValue, reset, resetField} = useForm({
    defaultValues: {
      manual: true,
      position: undefined,
      params: undefined,
      price: 0,
      email: "",
      code: ""
    },
    resolver: yupResolver(schema),
  })

  const isService = (position && (type.SERVICE.id === position.type.id))

  const onSubmit: SubmitHandler<IOrderState> = (values) => {
    dispatch(OrderActions.create(values)).then(
      async (bill) => {
        onClose(bill.order)
      },
      error => {
        reset({
          manual: true,
          position: values.position,
          params: values.params,
          price: values.price,
          email: values.email,
          code: values.code
        })
        if (error.hasOwnProperty("errors")) {
          Object.entries(error.errors).forEach(([name, message]) => {
            setError(name as keyof object, {type: "manual", message: message as string})
          })
        } else {
          dispatch({
            type: AlertActionsTypes.ERROR,
            payload: {
              type: "error",
              message: error,
            }
          })
        }
      }
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
    >
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
            <Grid item>
              <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={2}>
                <Field xs={8}>
                  <Controller
                    name="position"
                    control={control}
                    render={({
                      field: { onChange, value }, fieldState
                    }) => (
                      <Autocomplete
                        getOptionLabel={(option: IPosition) => {
                          let provider;

                          switch (option.service?.key) {
                            case 'steam':
                              provider = service[option.service?.key].find(service => service.key === option.service?.provider)?.name
                              break;
                          }

                          return `${option.name} ${provider ? `(${provider})` : ''}`
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        noOptionsText={"Нет данных"}
                        options={items}
                        loading={false}
                        value={value as IPosition}
                        onChange={(e, value) => {
                          setPosition(value)
                          onChange(value)
                          setValue('price', value?.price ?? 0)
                          resetField('params', undefined)
                        }}
                        renderTags={() => null}
                        renderInput={(params) => (
                          <TextField
                            required
                            label="Товар/Услуга:"
                            error={!!fieldState.error}
                            {...params}
                            onChange={(e) => {
                              debounced(e.target.value)
                            }}
                            helperText={fieldState.error?.message as string}
                          />
                        )}
                      />
                    )}
                  />
                </Field>
                <Field xs={4}>
                  <Controller
                    name="price"
                    control={control}
                    render={({
                      field: { onChange, value }, fieldState
                    }) => (
                      <TextField
                        disabled={!isService || !!position?.parent}
                        required
                        label="Сумма:"
                        type="number"
                        error={!!fieldState.error}
                        onChange={onChange}
                        value={value}
                        inputProps={{min: 0, step: 0.01}}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Field>
              </Grid>
            </Grid>
            {position?.params ? (
              <Grid item>
                <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={2}>
                  {Object.entries(position?.params).map(([name, param]) => (
                    <Field xs={12}>
                      <Controller
                        name={`params.${name}`}
                        control={control}
                        render={({
                          field: { onChange, value }, fieldState
                        }) => (
                          <TextField
                            label={`${param.name}:`}
                            error={!!fieldState.error}
                            onChange={onChange}
                            value={value}
                            helperText={fieldState.error?.message}
                            fullWidth
                          />
                        )}
                      />
                    </Field>
                  ))}
                </Grid>
              </Grid>
            ) : null}
            <Grid item>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="stretch" alignItems="stretch" spacing={2}>
                <Field xs={6}>
                  <Controller
                    name="email"
                    control={control}
                    render={({
                      field: { onChange, value }, fieldState
                    }) => (
                      <Account
                        value={value}
                        onChange={onChange}
                        error={fieldState.error}
                        required={true}
                        label="Электронная почта:"
                      />
                    )}
                  />
                </Field>
                <Field xs={6}>
                  <Controller
                    name="code"
                    control={control}
                    render={({
                      field: { onChange, value }, fieldState
                    }) => (
                      <TextField
                        label="Промокод:"
                        error={!!fieldState.error}
                        onChange={(e) => {
                          if (fieldState.error?.type === 'manual') {
                            clearErrors("code")
                          }
                          onChange(e)
                        }}
                        value={value}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Field>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="row" justifyContent="flex-end" alignItems="stretch">
                <Grid item>
                  <Button
                    disabled={isSubmitSuccessful}
                    size="large"
                    type="submit"
                  >
                    Создать
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}